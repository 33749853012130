@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/mediaqueries';

.accordion {
  .accordionButton {
    display: flex;
    width: 100%;
    border: none;
    background-color: colors.$white;
    cursor: pointer;
    text-align: left;
    padding: 0;

    color: colors.$neutral800;
    font-family: Jokker;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.18px;
    margin: 0 0 20px 0;
    @include mediaqueries.smallUp {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.14px;
    }

    .accordionIcon {
      margin-right: 4px;
      transform: rotate(-90deg);
      &.rotate {
        transform: rotate(0deg);
      }
    }
  }

  .accordionContent {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;

    ul {
      list-style-type: none;
      padding: 0;
      margin-top: 0;

      li {
        text-align: left;
        background-color: colors.$white;
        width: auto;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        a {
          width: 100%;
          padding: 8px;
          color: colors.$neutral600;
          font-family: Rubik;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          display: block;
          width: 100%;
          text-decoration: none;

          @include mediaqueries.smallUp {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
          }
        }
        &:hover {
          background-color: colors.$purple100;
          a {
            color: colors.$purple500;
            font-weight: 500;
          }
        }

        &.active,
        &:hover {
          background-color: colors.$purple100;
          a {
            color: colors.$purple500;
            font-weight: 500;
          }
        }
      }
    }

    &.isOpen {
      max-height: fit-content;
    }

    p {
      margin: 0.5rem 0;
    }
  }
}
